import {
    Form,
    Input, Button, Row,
} from 'antd';
import RoleSelector from "../authorization/roles/RoleSelector";
import WorkspaceSelector from "../workspace/WorkspaceSelector";
import {refreshEmployeeList} from "./reducer";
import useSyncQueryForm from "../../common/hooks/use-sync-query-form";
import {useDispatch} from "react-redux";
import {useCallback} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EnumSelector from "../../common/components/EnumSelector";
import {CommonStatus} from "../../common/enums";
import {isEqual} from "lodash";
import {useTranslation} from "react-i18next";

const toQuery = (changes: any) => ({
    ...changes,
    workspace_ids_str: changes.workspace_ids_str?.join(','),
    role_ids_str: changes.role_ids_str?.join(','),
});

const toFormValues = (data: any) => ({
    ...data,
    workspace_ids_str: data.workspace_ids_str ? data.workspace_ids_str?.split(',')?.map(Number) : undefined,
    role_ids_str: data.role_ids_str ? data.role_ids_str?.split(',')?.map(Number) : undefined,
    status: data.status ? Number(data.status) : undefined,
});

const SearchAndFilter = () => {
    const {t} = useTranslation('employee');
    const dispatch = useDispatch();

    const fetchHistories = useCallback(
        filter => {
            dispatch(refreshEmployeeList(filter));
        },
        [dispatch],
    );

    const [form, onValuesChange] = useSyncQueryForm<any>(
        {
            effect: fetchHistories,
            toQuery,
            toFormValues,
        },
    );

    const onSubmit = useCallback(() => {
        onValuesChange(form.getFieldsValue(), true);
    }, [onValuesChange, form])

    return (
        <Form
            form={form}
        >
            <div className={'flex items-center ml-12 mr-12'}>
                <Row gutter={24}>
                    <Form.Item
                        className={'w-300 mr-10'}
                        name={'email'}
                    >
                        <Input
                            allowClear
                            placeholder={t('filter.employee_placeholder')}
                        />
                    </Form.Item>

                    <Form.Item
                        className={'w-300 mr-10'}
                        name={'workspace_ids_str'}
                    >
                        <WorkspaceSelector
                            mode={'multiple'}
                            placeholder={t('filter.workspace_placeholder')}
                        />
                    </Form.Item>

                    <Form.Item noStyle
                               shouldUpdate={(a, b) => !isEqual(a.workspace_ids_str, b.workspace_ids_str)}>
                        {(form) => (
                            <Form.Item
                                className={'w-300 mr-10'}
                                name={'role_ids_str'}
                            >
                                <RoleSelector
                                    mode={'multiple'}
                                    hasAdmin={true}
                                    workspaceIds={form.getFieldValue('workspace_ids_str')}
                                    placeholder={t('filter.role_placeholder')}
                                />
                            </Form.Item>
                        )}
                    </Form.Item>


                    <Form.Item
                        className={'w-300 mr-10'}
                        name={'status'}
                    >
                        <EnumSelector
                            enumName={"CommonStatus"}
                            enumObj={CommonStatus}
                            placeholder={t('filter.active_status_placeholder')}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size={"middle"}
                            className={'h-38'}
                            onClick={onSubmit}
                        >
                            <FontAwesomeIcon icon={["fas", "search"]} />
                        </Button>
                    </Form.Item>
                </Row>
            </div>
        </Form>
    );
};

export default SearchAndFilter;
