import AppState from '../../../App.state';
import {createSelector} from '@reduxjs/toolkit';
import {Role} from "../../../models/role";

export const selectRoleListState = (state: AppState) => state.roleList;


export const selectRoleListLoading = createSelector(
    selectRoleListState,
    state => state.loading,
)

export const selectRoleList = createSelector(
    selectRoleListState,
    state => state.ids.map(id => state.list[id] as Role),
)

export const selectRoleById = (id?: number) => createSelector(
    selectRoleListState,
    state => state.list[id ?? 0],
)
