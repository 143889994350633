import {atom, useRecoilState} from "recoil";
import {Button, Form, Input, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {selectEmployeeById} from "./selectors";
import {useCallback, useEffect} from "react";
import {changingEmployeeRole} from "./reducer";
import RoleSelector from "../authorization/roles/RoleSelector";

export const editEmployeeModalVisibleState = atom<{ visible: boolean, employeeId?: number }>({
    key: 'editEmployeeModalVisibleState',
    default: {
        visible: false,
        employeeId: undefined,
    },
});

const EmployeeModalForm = () => {
    const [{visible, employeeId}, setIsModalVisible] = useRecoilState(editEmployeeModalVisibleState);

    const [form] = Form.useForm();

    const {t} = useTranslation('employee');

    const employee = useSelector(selectEmployeeById(employeeId));

    const dispatch = useDispatch();

    useEffect(() => {
        form.setFieldsValue({
            email: employee?.account?.email ?? '',
            workspace: employee?.workspace?.name ?? '',
            role_id: employee?.role_id
        });
    }, [employee, form]);

    const handleCancel = useCallback(() => {
        setIsModalVisible((old) => ({...old, visible: false}));
        form.setFieldsValue({
            email: employee?.account?.email ?? '',
            workspace: employee?.workspace?.name ?? '',
            role_id: employee?.role_id
        });
    }, [employee, form, setIsModalVisible]);

    const onFinish = useCallback((values: any) => {
        values.id = employeeId;
        dispatch(changingEmployeeRole(
            values,
        ));
        setIsModalVisible((old) => ({...old, visible: false}));
    }, [dispatch, employeeId, setIsModalVisible]);

    return (
        <Modal
            width={400}
            title="Chỉnh sửa nhân sự"
            visible={visible}
            onCancel={handleCancel}
            footer={null}
        >
            <Form
                labelCol={{span: 5}}
                wrapperCol={{span: 19}}
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    name={'email'}
                    label={t('table.employee')}
                >
                    <Input
                        disabled
                        defaultValue={employee?.account?.email ?? ''}
                    />
                </Form.Item>
                <Form.Item
                    name={'workspace'}
                    label={t('table.workspace')}
                >
                    <Input
                        disabled
                        defaultValue={employee?.workspace?.name ?? ''}
                        // defaultValue={employee?.account?.email ?? ''}
                    />
                </Form.Item>
                <Form.Item
                    name={'role_id'}
                    label={t('table.role')}
                    rules={[{
                        required: true,
                        message: 'Role không được để trống'
                    }]}
                >
                    <RoleSelector
                        hasAdmin={false}
                    />
                </Form.Item>
                <div className={'text-right'}>
                    <Button
                        key="back"
                        onClick={handleCancel}
                        className={'mr-5'}
                    >
                        Hủy
                    </Button>
                    <Button
                        key="link"
                        type="primary"
                        htmlType='submit'
                    >
                        Lưu
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default EmployeeModalForm;
