import {
    Button,
    Modal,
    Form,
} from "antd";
import {memo, useCallback, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EmployeeSelector from "../account/EmployeeSelector";
import RoleSelector from "../authorization/roles/RoleSelector";
import {useDispatch, useSelector} from "react-redux";
import {addingEmployee} from "./reducer";
import {useTranslation} from "react-i18next";
import WorkspaceFormControl from "./WorkspaceFormControl";
import {selectEmployeeFormStatus, selectEmployeeListFormLoading} from "./selectors";

const AddEmployee = memo(() => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const employeeFormStatus = useSelector(selectEmployeeFormStatus);

    const formLoading = useSelector(selectEmployeeListFormLoading);

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const {t} = useTranslation('employee');

    useEffect(() => {
        if (employeeFormStatus === 'success') {
            setIsModalVisible(false);
            form.resetFields();
        }
    }, [employeeFormStatus, form]);

    const showModal = useCallback(() => {
        setIsModalVisible(true);
    }, [setIsModalVisible]);

    const handleOk = useCallback(() => {
        setIsModalVisible(false);
        form.resetFields();
    }, [setIsModalVisible, form]);

    const handleCancel = useCallback(() => {
        setIsModalVisible(false);
        form.resetFields();
    }, [setIsModalVisible, form]);

    const onFinish = useCallback((values: any) => {
        dispatch(addingEmployee(values));
        // setIsModalVisible(false);
        // form.resetFields();
    }, [dispatch]);
    return (
        <>
            <Button
                onClick={showModal}
            >
                <FontAwesomeIcon icon={'user-plus'}/>
            </Button>
            <Modal
                width={400}
                title="Thêm mới nhân sự"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <Form
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                    preserve={false}
                    form={form}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name={'account_id'}
                        label={t('table.employee')}
                        rules={[{
                            required: true,
                            message: 'Nhân sự không được để trống'
                        }]}
                    >
                        <EmployeeSelector/>
                    </Form.Item>

                    <Form.Item
                        name={'role_id'}
                        label={t('table.role')}
                        rules={[{
                            required: true,
                            message: 'Role không được để trống'
                        }]}
                    >
                        <RoleSelector hasAdmin={false}/>
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(a, b) => a.role_id !== b.role_id}
                    >
                        {(form) => (
                            <WorkspaceFormControl
                                roleId={form.getFieldValue('role_id')}
                            />
                        )}
                    </Form.Item>
                    <div className={'text-right'}>
                        <Button
                            key="back"
                            onClick={handleCancel}
                            className={'mr-5'}
                        >
                            Hủy
                        </Button>
                        <Button
                            loading={formLoading}
                            type='primary'
                            htmlType='submit'>
                            Thêm mới
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
});
export default AddEmployee;
