import {RouterEnum} from "../../config/router.enum";
import axios from "../../common/axios";
import {Rest} from "../../common/models/rest";
import {BASE_URL} from "../../config/properties";
import {Workspace} from "../../models/workspace";


export class WorkspaceListService {
    getWorkspaceList () {
        return axios.get<Rest<Workspace[]>>(`${BASE_URL}/${RouterEnum.workspace}/workspaces`);
    }
}

const workspaceListService = new WorkspaceListService();

export default workspaceListService;
