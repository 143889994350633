import {useSelector} from "react-redux";
import {selectRoleById} from "../authorization/roles/selectors";
import {useMemo} from "react";
import {Form} from "antd";
import WorkspaceSelector from "../workspace/WorkspaceSelector";
import {useTranslation} from "react-i18next";

const WorkspaceFormControl = ({roleId}: {roleId: number}) => {
    const role = useSelector(selectRoleById(roleId));

    const {t} = useTranslation('employee');

    const rules = useMemo(() => {
        if (role?.workspace_id) {
            return [{
                required: true,
                message: 'Workspace không được để trống'
            }];
        }
        return undefined;
    }, [role]);

    return (
        <Form.Item
            name={'workspace_id'}
            rules={rules}
            label={t('table.workspace')}
        >
            <WorkspaceSelector/>
        </Form.Item>
    );
};

export default WorkspaceFormControl;
