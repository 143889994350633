import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Menu, Dropdown, Button } from "antd";
import { useTranslation } from "react-i18next";
import {LOGIN_URL} from "../../config/properties";
import auth from "../../features/auth/auth";

export default function ProfileButton() {
  const { t } = useTranslation();
  const logout = () => {
    auth.logout().then(() => {
      document.location.href = LOGIN_URL
    })
  }

  const userMenu = (
    <Menu>
      <Menu.Item
        key="header-logout"
        className="text-grey77"
        onClick={logout}
      >
        <FontAwesomeIcon
          className="mr-10 w-18 fa-w-18"
          icon={["far", "door-open"]}
        />
        {t("common:logout")}
      </Menu.Item>
    </Menu>
  );

  return (
      <Dropdown
          overlay={userMenu}
          placement="bottomLeft"
          arrow
          className="bg-greyFA rounded-full"
      >
        <Button type="text" className="h-48 px-10 cursor-pointer flex-i flex-row items-center">
          <Avatar
              className="mr-8 bg-mainLight text-main rounded-full"
              size={36}
              shape="square"
              src={auth?.user?.avatar}
          >
            {auth?.user?.fullname?.substr(0, 1)}
          </Avatar>
          <div className="mr-8 text-12 text-left w-140 overflow-hidden">
            <div className="font-medium truncate">{auth?.user?.fullname}</div>
            <div className="text-grey99 mb-0">
              {auth?.user?.code}
            </div>
          </div>
          <FontAwesomeIcon
              className="text-grey99 text-18"
              icon={["far", "chevron-down"]}
          />
        </Button>
      </Dropdown>
  );
}
