import {all, call, put, select, debounce} from "redux-saga/effects";
import {
    gettingRoleList,
    roleListLoaded,
} from "./reducer";
import {safeCall} from "../../../common/utils";
import {RoleListState} from "./state";
import {selectRoleListLoading} from "./selectors";
import {Rest} from "../../../common/models/rest";
import {Role} from "../../../models/role";
import roleListService from "./service";


const sagas = [
    debounce(100, gettingRoleList, safeCall(function* ({payload}) {
        const { loading } : RoleListState = yield select(selectRoleListLoading);
        if (loading) {
            return;
        }
        const response: Rest<Role[]> = yield call(roleListService.getRoleList);
        yield put(roleListLoaded(response));
    }))
];

function* roleSagas() {
    yield all(sagas);
}

export default roleSagas;
