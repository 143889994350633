import AppState from '../../App.state';
import {createSelector} from '@reduxjs/toolkit';
import {Workspace} from "../../models/workspace";

export const selectAccountPermissionState = (state: AppState) => state.accountPermission;

export const selectAccountPermissionEntities = createSelector(
    selectAccountPermissionState,
    state => state.ids.map(id => state.entities[id] as Workspace)
);
export const selectAccountPermissionIds = createSelector(
    selectAccountPermissionState,
    state => state.ids
);
export const selectAccountPermissionStatus = createSelector(
    selectAccountPermissionState,
    state => state.status
);
export const selectAccountPermissionFromWs = (slug: string) => createSelector(
    selectAccountPermissionState,
    state => state.entities[slug]
);
export const selectAccountPermissionWs = createSelector(
    selectAccountPermissionState,
    state => state.workspaces
);
export const selectAccountPermissionRoles = createSelector(
    selectAccountPermissionState,
    state => state.roles
);
export const selectAccountPermissionRolesFromWs = (ws: string) => createSelector(
    selectAccountPermissionState,
    state => state.roles[ws] ?? []
);
export const selectAccountPermissionSlugs = createSelector(
    selectAccountPermissionState,
    state => state.slugs
);
