import {CRUDService} from "../../common/service";
import {Employee, EmployeeStatus} from "../../models/employee";
import {RouterEnum} from "../../config/router.enum";
import axios from "../../common/axios";
import {Rest} from "../../common/models/rest";
import {BASE_URL} from "../../config/properties";


export class EmployeeListService extends CRUDService<Employee> {
    getNameSpace(): string {
        return RouterEnum.employee;
    }

    changeEmployeeStatus(data: EmployeeStatus) {
        return axios.post<Rest<Employee>>(`${BASE_URL}/${RouterEnum.employee}/change-status`, data);
    }

    getEmployeeRole() {
        return axios.get<Rest<Employee>>(`${BASE_URL}/${RouterEnum.employee}/roles`);
    }
}

const employeeListService = new EmployeeListService();

export default employeeListService;
