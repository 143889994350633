import {message} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ArgsProps, ConfigOnClose, MessageType} from "antd/lib/message";
import * as React from "react";
import {faCircleCheck, faTriangleExclamation} from "@fortawesome/pro-solid-svg-icons";
import colors from "../../config/colors.json";

declare type ConfigContent = React.ReactNode | string;
declare type ConfigDuration = number | (() => void);
declare type JointContent = ConfigContent | ArgsProps;

export function successMessage(content: JointContent, duration?: ConfigDuration, onClose?: ConfigOnClose): MessageType {
    const realContent = {
        content: typeof content == "string" ? content : undefined,
        className: 'message-success',
        icon: <FontAwesomeIcon
            icon={faCircleCheck}
            color={colors.green}
            className={'mr-8'}
        />,
    };
    return message.success(realContent, duration, onClose);
}

export function errorMessage(content: JointContent, duration?: ConfigDuration, onClose?: ConfigOnClose): MessageType {
    const realContent = {
        content: typeof content == "string" ? content : undefined,
        className: 'message-error',
        icon: <FontAwesomeIcon
            icon={faTriangleExclamation}
            color={colors.red}
            className={'mr-8'}
        />,
    };

    return message.error(realContent, duration, onClose);
}
