import {Popconfirm, Switch} from "antd";
import {useDispatch} from "react-redux";
import {memo} from "react";
import {changingEmployeeStatus} from "./reducer";
import {CommonStatus} from "../../common/enums";
import {useTranslation} from "react-i18next";

interface EmployeeStatusProps {
    status: number,
    id : number;
}
const EmployeeStatus = memo((props: EmployeeStatusProps) => {
    const dispatch = useDispatch();

    const {t} = useTranslation('common');

    const onChange = () => {
        dispatch(changingEmployeeStatus({
            id: props.id,
            status: props.status === CommonStatus.ACTIVE ? CommonStatus.INACTIVE : CommonStatus.ACTIVE
        }));
    }
    return (
        <div className={'text-center'}>
            <Popconfirm
                placement="topRight"
                title={props.status === CommonStatus.ACTIVE ? t('changeStatusActiveToInactive') : t('changeStatusInactiveToActive')}
                onConfirm={onChange}
                okText="Yes"
                cancelText="No"
            >
                <Switch
                    checked={props.status === CommonStatus.ACTIVE}
                />
            </Popconfirm>

        </div>
    );
});

export default EmployeeStatus;
