import {all} from 'redux-saga/effects';
import authSagas from './features/auth/sagas';
import {commonSagas} from './common/actions';
import employeeSagas from './features/employee/sagas';
import roleSagas from './features/authorization/roles/sagas';
import workspaceSagas from "./features/workspace/sagas";
import accountSagas from "./features/account/sagas";
import accountPermissionSagas from "./features/account-permission/sagas";

function* AppSagas() {
    yield all([
        commonSagas(),
        authSagas(),
        employeeSagas(),
        roleSagas(),
        workspaceSagas(),
        accountSagas(),
        accountPermissionSagas(),
    ]);
}

export default AppSagas;
