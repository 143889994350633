import {
    faPhone,
    faLock,
    faUserCircle,
    faBuilding,
    faCamera,
    faHome,
    faBars,
    faList,
    faFileLines,
    faUserGroup,
    faCalendar,
    faFingerprint,
    faCircleInfo,
    faBell,
    faDoorOpen,
    faDoorClosed,
    faArrowRightFromBracket,
    faPen,
    faUser,
    faStar,
    faTimes,
    faTimesCircle,
    faScrewdriverWrench,
    faEllipsis,
    faBriefcase,
    faSquareUser,
    faCreditCard,
    faCog,
    faCaretRight,
    faNote,
    faEnvelope,
    faCircle,
    faHourglass,
    faMemoCircleCheck,
    faClock,
    faChevronLeft,
    faChevronRight,
    faUsers,
    faCalendarDays,
    faArrowUp,
    faArrowDown,
    faFileSpreadsheet,
    faCalendarRange,
    faFiles,
    faClockRotateLeft,
    faQuoteRight,
    faComment,
    faCheckCircle,
    faBarcode,
    faGlobe,
    faPager,
    faBuildings,
    faPlus,
    faUpload,
    faMobileSignal,
    faMinus,
    faStarSharp,
    faGroup,
    faUserLarge,
    faRefresh,
    faBells,
    faGridHorizontal,
    faCircleQuestion,
    faGripHorizontal,
    faMessageLines,
    faLinkSimple,
    faSearch,
    faDashboard,
    faUserPlus,
    faPeopleArrowsLeftRight,
    faCircleCheck,
    faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";

import {
    faArrowLeft as farArrowLeft,
    faArrowRight as farArrowRight,
    faPlus as farPlus,
    faPen as farPen,
    faChevronDown as farChevronDown,
    faInfoCircle as farInfoCircle,
    faArrowRightFromBracket as farArrowRightFromBracket,
    faDoorOpen as farDoorOpen,
    faDoorClosed as farDoorClosed,
    faBuilding as farBuilding,
    faArrowLeftLong as farArrowLeftLong,
    faBell as farBell,
    faSearch as farSearch,
    faScrewdriverWrench as farScrewdriverWrench,
    faShare as farShare,
    faRepeat as farRepeat,
    faCircleInfo as farCircleInfo,
    faTrash as farTrash,
    faEdit as farEdit,
    faTrashCan as farTrashCan,
    faClone as farClone,
    faCheck as farCheck,
    faXmark as farXmark,
    faExclamation as farExclamation,
    faInfo as farInfo,
    faBriefcase as farBriefcase,
    faBriefcaseClock as farBriefcaseClock,
    faHourglass as farHourglass,
    faGear as farGear,
    faCalendarDays as farCalendarDays,
    faListCheck as farListCheck,
    faArrowUp as farArrowUp,
    faArrowDown as farArrowDown,
    faTimes as farTimes,
    faCheckCircle as farCheckCircle,
    faLocationDot as farLocationDot,
    faCalendarCheck as farCalendarCheck,
    faEllipsisH as farEllipsisH,
    faEye as farEye,
    faClock as farClock,
    faCopy as farCopy,
    faUpload as farUpload,
} from "@fortawesome/pro-regular-svg-icons";

import {
    faClipboardUser as fatClipboardUser,
    faKey as fatKey,
    faBuildings as fatBuildings,
    faBuilding as fatBuilding,
    faShieldCheck as fatShieldCheck,
    faScreenUsers as fatScreenUsers,
    faMemoCircleCheck as fatMemoCircleCheck,
    faMemoPad as fatMemoPad,
    faGear as fatGear,
    faTimeline as fatTimeline,
    faWifi as fatWifi,
    faFingerprint as fatFingerprint,
    faCalendar as fatCalendar,
    faFileLines as fatFileLines,
    faClipboard as fatClipboard,
    faClipboardList as fatClipboardList,
    faCalendarDay as fatCalendarDay,
    faCalendarCheck as fatCalendarCheck,
    faFilePen as fatFilePen,
} from "@fortawesome/pro-thin-svg-icons";

const fontAwesomeIcons = {
    // Solid
    faUserCircle,
    faPhone,
    faLock,
    faBuilding,
    faCamera,
    faHome,
    faBars,
    faList,
    faFileLines,
    faCalendar,
    faFingerprint,
    faCircleInfo,
    faUserGroup,
    faBell,
    faDoorOpen,
    faArrowRightFromBracket,
    faPen,
    faUser,
    faStar,
    faTimes,
    faScrewdriverWrench,
    faEllipsis,
    faBriefcase,
    faSquareUser,
    faCreditCard,
    faCog,
    faCaretRight,
    faNote,
    faEnvelope,
    faDoorClosed,
    faCircle,
    faHourglass,
    faMemoCircleCheck,
    faClock,
    faChevronLeft,
    faChevronRight,
    faUsers,
    faCalendarDays,
    faFileSpreadsheet,
    faCalendarRange,
    faFiles,
    faClockRotateLeft,
    faQuoteRight,
    faComment,
    faCheckCircle,
    faBarcode,
    faGlobe,
    faPager,
    faBuildings,
    faPlus,
    faUpload,
    faTimesCircle,
    faMobileSignal,
    faMinus,
    faStarSharp,
    faGroup,
    faUserLarge,
    faRefresh,
    faBells,
    faGridHorizontal,
    faCircleQuestion,
    faGripHorizontal,
    faMessageLines,
    faLinkSimple,
    faSearch,
    faDashboard,
    faUserPlus,
    faPeopleArrowsLeftRight,

    // Regular
    farArrowLeft,
    farArrowRight,
    farPlus,
    farPen,
    farChevronDown,
    farInfoCircle,
    farArrowRightFromBracket,
    farDoorOpen,
    farBuilding,
    farArrowLeftLong,
    farBell,
    farSearch,
    farScrewdriverWrench,
    farShare,
    farRepeat,
    farCircleInfo,
    farTrash,
    farEdit,
    farTrashCan,
    farClone,
    farCheck,
    farBriefcase,
    farBriefcaseClock,
    farHourglass,
    farDoorClosed,
    farGear,
    farCalendarDays,
    farListCheck,
    farArrowUp,
    farArrowDown,
    farTimes,
    farCheckCircle,
    farLocationDot,
    farCalendarCheck,
    farEllipsisH,
    farEye,
    farClock,
    farCopy,
    farUpload,

    // Thin
    fatClipboardUser,
    fatKey,
    fatBuildings,
    fatBuilding,
    fatShieldCheck,
    fatScreenUsers,
    fatMemoCircleCheck,
    fatMemoPad,
    fatGear,
    fatWifi,
    fatTimeline,
    fatFingerprint,
    fatCalendar,
    farXmark,
    farExclamation,
    farInfo,
    fatFileLines,
    faArrowUp,
    faArrowDown,
    fatClipboard,
    fatClipboardList,
    fatCalendarDay,
    fatCalendarCheck,
    fatFilePen,
    faCircleCheck,
    faTriangleExclamation,
};

export default fontAwesomeIcons;
