import AppState from '../../App.state';
import {createSelector} from '@reduxjs/toolkit';
import {Workspace} from "../../models/workspace";

export const selectWorkspaceListState = (state: AppState) => state.workspaceList;

export const selectWorkspaceListLoading = createSelector(
    selectWorkspaceListState,
    state => state.loading,
)
export const selectWorkspaceList = createSelector(
    selectWorkspaceListState,
    state => state.ids.map(id => state.list[id] as Workspace),
)
