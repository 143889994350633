import { Dictionary } from '@reduxjs/toolkit';
import { StateStatus } from '../../common/enums';
import {Workspace} from "../../models/workspace";
import {Role} from "../../models/role";

export class AccountPermissionParam {
    workspace?: number = 0;
    role?: number = 0;
}

export class AccountPermissionState {
    ids: number[] = [];
    entities: Dictionary<Workspace> = {};
    status: StateStatus = 'idle';
    workspaces: Workspace[] = [];
    roles: Dictionary<Role[]> = {};
    slugs: string[] = [];
}
