import {Select} from "antd";
import {SelectProps} from "antd/es";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {gettingWorkspaceList} from "./reducer";
import {selectWorkspaceList} from "./selectors";
import {Workspace} from "../../models/workspace";

export interface WorkspaceSelectorProps extends SelectProps<number> {}
const WorkspaceSelector = (props: WorkspaceSelectorProps) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(gettingWorkspaceList());
    }, [dispatch]);

    const data = useSelector(selectWorkspaceList);

    return (
        <Select
            showSearch
            placeholder={"Workspace"}
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) =>
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
            {...props}>
            {
                data.map((value: Workspace, index: number) => (
                    <Select.Option value={value.id} key={value.id}>{value.name}</Select.Option>
                ))
            }

        </Select>
    );
}
export default WorkspaceSelector;
