import ReactDOM from 'react-dom';
import App from './App';
import {Router} from 'react-router-dom';
import history from './config/history';
import {Provider} from 'react-redux';
import store from './config/store';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import {RecoilRoot} from 'recoil';
import {ConfigProvider} from 'antd';
import vi from 'antd/es/locale/vi_VN';
import './config/i18next';
import './config/antd';
import './index.less';
import './index.scss';
import hookGuard from 'react-hook-guard';
import { library } from "@fortawesome/fontawesome-svg-core";
import fontAwesomeIcons from "./utils/fontAwesomeIcons";
import HealthCheck from "./common/components/HealthCheck";
import GlobalLoading from "./common/components/GlobalLoading/index";
import auth from "./features/auth/auth";
import customizeEmpty from "./utils/customizeEmpty";

if(vi.DatePicker?.lang) vi.DatePicker.lang.locale = 'vi';
//@ts-ignore
library.add(fontAwesomeIcons);

hookGuard.config({
    SuspenseFallback: GlobalLoading
});

// eslint-disable-next-line no-restricted-globals
if (location.pathname === `${process.env.REACT_APP_PREFIX}/health`) {
    ReactDOM.render(
        <HealthCheck />,
        document.getElementById('root')
    )
} else {
    auth.init().then((auth) => {
        auth.runRefreshTokenInterval()
        ReactDOM.render(
            <ConfigProvider locale={vi} renderEmpty={customizeEmpty('common:emptyData')}>
                <I18nextProvider i18n={i18next}>
                    <Provider store={store}>
                        <Router history={history}>
                            <RecoilRoot>
                                <App/>
                            </RecoilRoot>
                        </Router>
                    </Provider>
                </I18nextProvider>
            </ConfigProvider>,
            document.getElementById('root')
        );
    })
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
    reportWebVitals();
}
