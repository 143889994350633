import { CRUDService } from '../../common/service';
import { Workspace } from '../../models/workspace';
import {RouterEnum} from "../../config/router.enum";

export class AccountPermissionService extends CRUDService<Workspace> {
    getNameSpace(): string {
        return RouterEnum.workspace + '/' + RouterEnum.getListAccountPermission;
    }
}

const accountPermissionService = new AccountPermissionService();

export default accountPermissionService;
