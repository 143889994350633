import authService from "../../features/auth/service";
import {User} from "../../models/user";
import {Employee} from "../../models/employee";
const jwt = require('jsonwebtoken');
export const TOKEN_KEY = '__auth_token';

class Auth {
    token?: string;
    info: any;
    user: User | undefined;
    intervalTime = 10 * 60; // 10 phút check token 1 lần
    error?: string | undefined;
    employee: Employee | undefined;
    workspace: string | undefined;

    constructor(private readonly url: string) {
    }

    private isTokenValid() {
        if (this.getRemainTTL() > 0) {
            return true
        }
        return false
    }

    public hasPermissions(permissions: string[] | string) {
        return true
        // if (!permissions) {
        //     return true
        // }
        // const arrPermissions: Array<string> = (typeof permissions == 'string') ? [permissions] : permissions
        // const employeePermissions = this.employee?.role?.permissions ?? []
        // return arrPermissions.some(e => employeePermissions.includes(e));
    }

    private getRemainTTL() {
        return (this.info?.exp ?? 0) - new Date().getTime() / 1000
    }

    private initInfo(token?: string) {
        this.token = token
        this.info = jwt.decode(token)
    }

    private async fetchUserInfo() {
        const res = await authService.userData()
        this.user = res.data
        return res
    }

    async setWorkspace(workspace: string) {
        this.workspace = workspace
        // const res = await authService.employeeData(workspace)
        // this.employee = res.data
    }

    private saveToken(token: string) {
        this.token = token
        localStorage.setItem(TOKEN_KEY, token)
    }

    public runRefreshTokenInterval() {
        setInterval(() => {
            if (this.getRemainTTL() <= this.intervalTime) {
                this.refreshToken()
            }
        }, 10 * 60 * 1000)
    }

    async refreshToken() {
        const res = await authService.refreshToken()
        this.saveToken(res.data.token)
    }

    goLogin() {
        window.location.href = this.url
    }

    async logout() {
        await authService.logout()
        localStorage.removeItem(TOKEN_KEY)
        this.initInfo('')
        this.user = undefined
        return new Promise((resolve, reject) => {
            resolve(this)
        })
    }

    getStoredToken() {
        return localStorage.getItem(TOKEN_KEY)
    }

    async init(): Promise<Auth> {
        const urlSearchParams = new URLSearchParams(window.location.search)
        let token = urlSearchParams.get('token')
        if (token) {
            this.saveToken(token!)
        } else {
            token = localStorage.getItem(TOKEN_KEY)
        }
        if (!token) {
            this.goLogin()
            return Promise.reject("Not detect any token");
        }
        this.initInfo(token!)
        if (!this.isTokenValid()) {
            this.goLogin()
            return Promise.reject("Invalid token");
        }
        await this.fetchUserInfo()
        return this
    }
}

export default Auth;
