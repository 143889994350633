import AppState from '../../App.state';
import {createSelector} from '@reduxjs/toolkit';

export const selectAccountListState = (state: AppState) => state.accountList;

export const selectAccountListData = createSelector(
    selectAccountListState,
    state => state.data,
)

export const selectAccountListLoading = createSelector(
    selectAccountListState,
    state => state.loading,
)
