import {memo, useMemo} from "react";
import {generatePath, Link, LinkProps, useRouteMatch} from "react-router-dom";

const WithParamsLink = memo(({to, ...props}: LinkProps) => {
    const match = useRouteMatch();
    const realTo = useMemo(() => typeof to === 'string' ? generatePath<string>(to, match.params) : to, [match, to]);

    return <Link to={realTo} {...props} />;
});

export default WithParamsLink;
