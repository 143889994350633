import {Employee} from "../../models/employee";
import {Dictionary} from "@reduxjs/toolkit";
import {PaginationMeta} from "../../common/models/pagination-meta";
import {StateStatus} from "../../common/enums";

export class EmployeeParams {
    email?: string = undefined;
    role_id?: number = undefined;
    status?: boolean = undefined;
}

export class EmployeeListState {
    loading: boolean = false;
    formLoading: boolean = false;
    ids:  number[] = [];
    tableData: Dictionary<Employee> = {};
    message: string = '';
    total: number = 0;
    meta?: PaginationMeta;
    formStatus: StateStatus = 'idle';
}
