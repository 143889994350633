import fontAwesomeIcons from '../utils/fontAwesomeIcons';
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {RouterEnum} from "./router.enum";

type Sider = {
  icon: IconProp;
  title: string;
  path: string;
};

const siderItems: Sider[] = [
  {
    icon: fontAwesomeIcons.faUser,
    title: "menu:employee",
    path: "employee",
  },
  {
    icon: fontAwesomeIcons.faPeopleArrowsLeftRight,
    title: "menu:roles",
    path: RouterEnum.accountPermission,
  },
];

export default siderItems;
