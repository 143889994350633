import {combineReducers} from 'redux';
import auth from './features/auth/reducer';
import accountPermission from './features/account-permission/reducer';
import employeeList from "./features/employee/reducer";
import roleList from "./features/authorization/roles/reducer";
import workspaceList from "./features/workspace/reducer";
import accountList from "./features/account/reducer";

const AppReducer = combineReducers({
    auth,
    accountPermission,
    employeeList,
    roleList,
    workspaceList,
    accountList,
});

export default AppReducer;
