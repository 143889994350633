import {createSlice, Dictionary, PayloadAction} from "@reduxjs/toolkit";
import {WorkspaceListState} from "./state";
import {Workspace} from "../../models/workspace";
import {Rest} from "../../common/models/rest";


const slice = createSlice({
    name: 'workspaceList',
    initialState: {...new WorkspaceListState()},
    reducers: {
        gettingWorkspaceList(state: WorkspaceListState) {
            state.loading = true;
        },
        workspaceListLoaded(state: WorkspaceListState, {payload}: PayloadAction<Rest<Workspace[]>>) {
            const workspaceList: Dictionary<Workspace> = {};

            const getIds = (workspaceData: Workspace[]) => {
                return workspaceData.map(workspace => {
                    const {...restInfo} = workspace;
                    if (restInfo) workspaceList[workspace.id] = restInfo;
                    return workspace.id;
                });
            };
            state.list = workspaceList;
            state.ids = getIds(payload.data);
            state.loading = false;
        }

    }
});

export const {
    gettingWorkspaceList,
    workspaceListLoaded,
} = slice.actions;

const workspaceListReducer = slice.reducer;

export default workspaceListReducer;
