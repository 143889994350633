export enum RouterEnum {
  root = '',
  login = 'login',
  dashboard = 'dashboard',
  create = 'create',
  edit = 'edit',
  delete = 'delete',
  list = 'list',
  categories = 'categories',
  employee = 'employee',
  roles = 'roles',
  account = 'account',
  workspace = 'workspace',
  getListAccountPermission = 'get-all-account-permission',
  accountPermission = 'account-permission',
  employees = 'employees',
}
