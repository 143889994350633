import AppState from '../../App.state';
import {createSelector} from '@reduxjs/toolkit';
import {Employee} from "../../models/employee";

const selectEmployeeListState = (state: AppState) => state.employeeList;


export const selectEmployeeListTableData = createSelector(
    selectEmployeeListState,
    state => state.ids.map(id => state.tableData[id] as Employee),
);

export const selectEmployeeListTotal = createSelector(
    selectEmployeeListState,
    state => state.total,
)

export const selectEmployeeListLoading = createSelector(
    selectEmployeeListState,
    state => state.loading,
)

export const selectEmployeeListFormLoading = createSelector(
    selectEmployeeListState,
    state => state.formStatus === 'loading',
)

export const selectEmployeeMeta = createSelector(
    selectEmployeeListState,
    state => state.meta,
)

export const selectEmployeeById = (id?: number) => createSelector(
    selectEmployeeListState,
    state => state.tableData[id ?? 0],
)

export const selectEmployeeFormStatus = createSelector(
    selectEmployeeListState,
    state => state.formStatus,
)
