import {all, select, call, put, takeLeading} from 'redux-saga/effects';
import { AccountPermissionState } from './state';
import { selectAccountPermissionState } from './selectors';
import accountPermissionService from './service';
import {
    listAccountPermission,
    listAccountPermissionLoaded
} from './reducer';
import {safeCall} from '../../common/utils';
import {Rest} from '../../common/models/rest';
import {Workspace} from "../../models/workspace";

const sagas = [
    takeLeading(listAccountPermission, safeCall(function* ({payload}) {
        const { status }: AccountPermissionState = yield select(selectAccountPermissionState);
        if (status !== 'loading') {
            return;
        }
        const response: Rest<Workspace[]> = yield call(accountPermissionService.list, payload);
        yield put(listAccountPermissionLoaded(response));
    })),
];

function* accountPermissionSagas() {
    yield all(sagas);
}

export default accountPermissionSagas;
