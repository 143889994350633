import {Select} from "antd";
import {SelectProps} from "antd/es";
import {memo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {gettingAccountList} from "./reducer";
import {selectAccountListData} from "./selectors";
import {Account} from "../../models/account";

export interface EmployeeSelectorProps extends SelectProps<number> {}
const AccountSelector = memo((props: EmployeeSelectorProps) => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(gettingAccountList());
    }, [dispatch]);

    const data = useSelector(selectAccountListData);

    return (
        <Select
            showSearch
            placeholder={"Nhân sự"}
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) =>
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
            {...props}>
            {
                data.map((value: Account, index: number) => (
                    <Select.Option value={value.id} key={value.id}>{value.email}</Select.Option>
                ))
            }
        </Select>
    );
});

export default AccountSelector;

