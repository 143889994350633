import {all, call, put, select, takeLeading} from "redux-saga/effects";
import {
    gettingWorkspaceList, workspaceListLoaded

} from "./reducer";
import {safeCall} from "../../common/utils";
import {Rest} from "../../common/models/rest";
import {WorkspaceListState} from "./state";
import {selectWorkspaceListLoading} from "./selectors";
import {Workspace} from "../../models/workspace";
import workspaceListService from "./service";


const sagas = [
    takeLeading(gettingWorkspaceList, safeCall(function* ({payload}) {
        const { loading } : WorkspaceListState = yield select(selectWorkspaceListLoading);
        if (loading) {
            return;
        }
        const response: Rest<Workspace[]> = yield call(workspaceListService.getWorkspaceList);
        yield put(workspaceListLoaded(response));
    })),
];

function* workspaceSagas() {
    yield all(sagas);
}

export default workspaceSagas;
