import {Button} from "antd";
import {memo, useCallback} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import fontAwesomeIcons from "../../utils/fontAwesomeIcons";
import {useSetRecoilState} from "recoil";
import {editEmployeeModalVisibleState} from "./EditEmployeeModalForm";

interface EditEmployeeProps {
    employeeId: number;
}

const EditEmployee = memo(({employeeId, ...props}: EditEmployeeProps) => {
    const setModalVisible = useSetRecoilState(editEmployeeModalVisibleState);

    const showModal = useCallback(() => {
        setModalVisible({visible: true, employeeId});
    }, [employeeId, setModalVisible]);

    return (
        <>
            <Button
                onClick={showModal}
            >
                <FontAwesomeIcon icon={fontAwesomeIcons.farEdit}/>
            </Button>
        </>
    );
});
export default EditEmployee;
