import {all, call, put, select, takeLeading} from "redux-saga/effects";
import {
    addingEmployee, changingEmployeeRole,
    changingEmployeeStatus,
    employeeAdded,
    employeeListLoaded, employeeStatusChanged,
    refreshEmployeeList, addingEmployeeFailed
} from "./reducer";
import {safeCall} from "../../common/utils";
import {EmployeeListState} from "./state";
import {selectEmployeeListFormLoading, selectEmployeeListLoading} from "./selectors";
import {Rest} from "../../common/models/rest";
import employeeListService from './service';
import {Employee} from "../../models/employee";
import {successMessage} from "../../common/utils/ant-message";
import i18next from "i18next";

const sagas = [
    takeLeading(refreshEmployeeList, safeCall(function* ({payload}) {
        const {loading}: EmployeeListState = yield select(selectEmployeeListLoading);
        if (loading) {
            return;
        }
        const response: Rest<Employee[]> = yield call(employeeListService.list, payload);
        yield put(employeeListLoaded(response));
    })),

    takeLeading(addingEmployee, function* ({payload}) {
        try {
            const {formLoading}: EmployeeListState = yield select(selectEmployeeListFormLoading);
            if (formLoading) {
                return;
            }
            const response: Rest<Employee> = yield call(employeeListService.create, payload);
            yield put(employeeAdded(response.data));
            successMessage(i18next.t('common:addEmployeeSuccess'));
        } catch (e) {
            yield put(addingEmployeeFailed());
        }
    }),

    takeLeading(changingEmployeeStatus, safeCall(function* ({payload}) {
        const {formLoading}: EmployeeListState = yield select(selectEmployeeListFormLoading);
        if (formLoading) {
            return;
        }
        const response: Rest<Employee> = yield call(employeeListService.changeEmployeeStatus, payload);
        yield put(employeeStatusChanged(response.data));
        successMessage(i18next.t('common:changeStatusSuccess'));
    })),

    takeLeading(changingEmployeeRole, safeCall(function* ({payload}) {
        const {formLoading}: EmployeeListState = yield select(selectEmployeeListFormLoading);
        if (formLoading) {
            return;
        }
        const response: Rest<Employee> = yield call(employeeListService.edit, payload?.id, payload);
        yield put(employeeAdded(response.data));
        successMessage(i18next.t('common:updateEmployeeSuccess'));
    })),
];

function* employeeSagas() {
    yield all(sagas);
}

export default employeeSagas;
