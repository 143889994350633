import {createSlice, Dictionary, PayloadAction} from "@reduxjs/toolkit";
import {RoleListState} from "./state";
import {Role} from "../../../models/role";
import {isEqual} from "lodash";
import {Rest} from "../../../common/models/rest";

let previousWorkspace: number[]|undefined;

const slice = createSlice({
    name: 'roleList',
    initialState: {...new RoleListState()},
    reducers: {
        gettingRoleList(state: RoleListState, {payload}: PayloadAction<number[]|undefined>) {
            if (!isEqual(payload, previousWorkspace)) {
                previousWorkspace = payload;
                state.loading = true;
            }
        },
        roleListLoaded(state: RoleListState, {payload}: PayloadAction<Rest<Role[]>>) {

            const roleList: Dictionary<Role> = {};

            const getIds = (roleData: Role[]) => {
                return roleData.map(role => {
                    const {...restInfo} = role;
                    if (restInfo) roleList[role.id] = restInfo;
                    return role.id;
                });
            };
            state.ids = getIds(payload.data);
            state.loading = false;
            state.list = roleList;
        },

    }
});

export const {
    gettingRoleList,
    roleListLoaded,
} = slice.actions;

const roleListReducer = slice.reducer;

export default roleListReducer;
