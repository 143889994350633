import {Select} from "antd";
import {SelectProps} from "antd/es";
import {useDispatch, useSelector} from "react-redux";
import {gettingRoleList} from "./reducer";
import {selectRoleList} from "./selectors";
import {useEffect} from "react";
import {Role} from "../../../models/role";
import {useTranslation} from "react-i18next";

export interface RoleSelectorProps extends SelectProps<number> {
    hasAdmin?: boolean;
    workspaceIds?: number[];
}
const RoleSelector = ({hasAdmin, workspaceIds, allowClear, showSearch, placeholder,...props}: RoleSelectorProps) => {
    const dispatch = useDispatch();

    const {t} = useTranslation('employee');

    useEffect(() => {
        dispatch(gettingRoleList(workspaceIds));
    }, [dispatch, workspaceIds]);

    const data = useSelector(selectRoleList);

    return (
        <Select
            showSearch={showSearch ?? true}
            placeholder={placeholder ?? t('table.role')}
            allowClear={allowClear ?? true}
            optionFilterProp="children"
            filterOption={(input, option) =>
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
            {...props}>
            {
                hasAdmin
                    ?
                data.map((value: Role, index: number) =>
                    (<Select.Option value={value.id} key={value.id}>{value.name}</Select.Option>)
                )
                    :
                data.filter((value: Role) => !value.is_admin).map((value: Role, index: number) =>
                (<Select.Option value={value.id} key={value.id}>{value.name}</Select.Option>)
                )

            }
        </Select>
    )
}

export default RoleSelector;
