import React, {memo, useCallback, useEffect} from "react";
import {Layout, Menu, Button, Spin} from "antd";
import {useState} from "react";
import siderMenu from "../config/siderMenu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RouterOutlet, RouteWithCommand} from "react-hook-guard";
import {useTranslation} from "react-i18next";
import ProfileButton from "./components/ProfileButton";
import DocumentTitle from "./components/Title";
import {TFunction} from "i18next";
import WithParamsLink from "../common/components/WithParamsLink";
import logo from './images/logo.png';


const {Header, Content, Sider} = Layout;

const siderMenuRender = (t: TFunction, route: RouteWithCommand) => {
    return siderMenu.map((menu) => (
        <Menu.Item
            className="hover:bg-white hover:text-grey77"
            icon={
                <FontAwesomeIcon
                    className="w-18 fa-w-18 opacity-80"
                    icon={menu.icon}
                />
            }
            key={`${route.absolutePath}${menu.path}`}
            onClick={() => {
            }}
        >
            <WithParamsLink to={`${route.absolutePath}${menu.path}`}><span>{t(menu.title)}</span></WithParamsLink>
        </Menu.Item>
    ))
};

// const NotiBell = () => (
//     <div className="mr-16">
//         <Badge size={'small'} count={12} offset={[-48, 12]} color={"red"} className={"mt-12"}>
//             <Button
//                 type="text"
//                 className="h-48 w-48 flex-i items-center justify-center bg-greyF3F4F5 relative rounded-full"
//             >
//                 <FontAwesomeIcon className="text-grey21 text-20" icon={["fas", "bells"]}/>
//             </Button>
//         </Badge>
//     </div>
// );
// const HelpButton = () => (
//     <div className="mr-16">
//         <Button
//             type="text"
//             className="h-48 w-48 flex-i items-center justify-center bg-greyF3F4F5 relative rounded-full"
//         >
//             <FontAwesomeIcon className="text-grey21 text-20" icon={["fas", "question-circle"]}/>
//         </Button>
//     </div>
// );
//
// const AppButton = () => (
//     <div className="mr-16">
//         <Button
//             type="text"
//             className="h-48 w-48 flex-i items-center justify-center bg-greyF3F4F5 relative rounded-full"
//         >
//             <FontAwesomeIcon className="text-grey21 text-20" icon={['fas', 'grip-horizontal']}/>
//         </Button>
//     </div>
// );

const SiderLayout = (props: any) => {
    const {t} = useTranslation();
    const isDisplayMenu = props.parentRoute.data?.menuDisplay === true;
    const [collapsed, setCollapsed] = useState(true);
    useEffect(() => {
        setCollapsed(siderMenu?.length === 1);
    }, [setCollapsed]);

    const collapse = useCallback(() => {
        setCollapsed(true);
    }, []);

    const toggleCollapse = useCallback(() => {
        setCollapsed(prevState => !prevState);
    }, []);
    const isLoading = false;
    // const currentPathname = useCurrentPathName(props.parentRoute, props.routes);
    return (
        <Layout style={{minHeight: "100vh"}}>
            <Header
                className="bg-white fixed w-full z-10 flex p-0"
                style={{boxShadow: "0px 3px 6px #0000000D"}}
            >
                <div className={"w-180 flex flex-row items-center pl-24"}>
                    {
                        isDisplayMenu ?
                            (
                                <Button
                                className="w-32 text-center p-0"
                                onClick={toggleCollapse}
                                type="text"
                            >
                                <FontAwesomeIcon className="text-main w-18 fa-w-18" icon="bars"/>
                            </Button>
                            ) : ('')
                    }
                    <img
                        src={logo}
                        className="ml-30 cursor-pointer"
                        alt={'icon'}
                        onClick={() => {
                        }}
                    />
                </div>
                <div className="flex justify-between flex-row mx-16 h-full w-full">
                    <DocumentTitle/>

                    <div className="flex flex-row items-center">
                        {/*<HelpButton/>*/}
                        {/*<AppButton/>*/}
                        {/*<NotiBell/>*/}
                        <ProfileButton/>
                    </div>
                </div>
            </Header>
            <Layout style={{marginTop: 63}}>
                { isDisplayMenu ?
                    (
                        <Sider
                            collapsedWidth={0}
                            onBreakpoint={collapse}
                            breakpoint="sm"
                            style={{boxShadow: "0px 3px 6px #00000029"}}
                            collapsible
                            collapsed={collapsed}
                            trigger={null}
                            width={216}
                            className="sider-menu fixed h-full bg-white z-10"
                            >
                            <Menu mode="inline" selectedKeys={[props.location.pathname]}>
                                {siderMenuRender(t, props.parentRoute)}
                            </Menu>
                        </Sider>
                            ) : ('')
                }
                <Layout style={isDisplayMenu ? {marginLeft: collapsed ? 0 : 216} : {}}>
                    <Content className="bg-white">
                        <div
                            className="h-screen flex items-center justify-center "
                            style={{display: isLoading ? undefined : "none"}}
                        >
                            <Spin size="large"/>
                        </div>
                        <div
                            style={{
                                minHeight: "90vh",
                                display: isLoading ? "none" : undefined,
                            }}
                        >
                            <RouterOutlet {...props} />
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
};

export default memo(SiderLayout);
