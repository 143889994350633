import {MenuType, RouteData} from './core/router';
import {Routes} from 'react-hook-guard';
import {lazy} from 'react';
import {DashboardOutlined,} from '@ant-design/icons';
import SiderLayout from "./layouts/SiderLayout";
import {RouterEnum} from './common/enums';
import EmployeeList from "./features/employee";
import {useRouteTitleGuard} from "./common/title";


const appRoutes: Routes<RouteData> = [
    {
        path: 'auth',
        component: SiderLayout,
        canActivateChild: [useRouteTitleGuard],
        children: [
            {
                path: RouterEnum.login,
                component: lazy(() => import('./features/auth/login')),
                data: {
                    title: 'Đăng nhập',
                    menuDisplay: false,
                }
            },
            {
                path: 'auth',
                redirectTo: RouterEnum.login
            }
        ],
        data: {
            menuDisplay: false
        },
    },
    {
      path: RouterEnum.root,
      exact: true,
      redirectTo: RouterEnum.employee
    },
    {
        path: RouterEnum.root,
        component: SiderLayout,
        data: {
            menuDisplay: true
        },
        canActivateChild: [useRouteTitleGuard],
        children: [
            {
                path: RouterEnum.employee,
                component: EmployeeList,
                data: {
                    title: 'Danh sách nhân sự',
                    menuType: MenuType.ITEM,
                    menuDisplay: true,
                    icon: DashboardOutlined
                }
            },
            {
                path: RouterEnum.roles,
                component: lazy(() => import('./features/auth/login')),
                data: {
                    title: 'Testy',
                    menuType: MenuType.ITEM,
                    menuDisplay: true,
                    icon: DashboardOutlined
                }
            },
            {
                path: RouterEnum.accountPermission,
                component: lazy(() => import('./features/account-permission/AccountPermission/index')),
                data: {
                    title: 'Phân quyền tài khoản',
                    menuType: MenuType.ITEM,
                    menuDisplay: true,
                    icon: DashboardOutlined
                }
            },
        ]
    }
];

export default appRoutes;
