import SearchAndFilter from "./SearchAndFilter";
import AddEmployee from "./AddEmployee";
import EditEmployee from "./EditEmployee";
import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {refreshEmployeeList} from "./reducer";
import {
    selectEmployeeListLoading,
    selectEmployeeListTableData,
    selectEmployeeListTotal,
    selectEmployeeMeta
} from "./selectors";
import {useTranslation} from "react-i18next";
import {Employee} from "../../models/employee";
import {DISPLAY_DATE_FORMAT} from "../../common/constants";
import format from "date-fns/format";
import EmployeeStatus from "./EmployeeStatus";
import {Table} from "antd";
import {useQuery} from "../../common/hooks";
import {useHistory} from "react-router-dom";
import EmployeeModalForm from "./EditEmployeeModalForm";

const defaultConfigTable: Object = {
    bordered: false,
    size: 'default',
    title: undefined,
    scroll: undefined,
    hasData: true,
    tableLayout: undefined,
    pagination: false,
};

const EmployeeList = (props: any) => {
    const {t} = useTranslation('employee');

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(refreshEmployeeList({}))
    }, [dispatch]);

    const tableData = useSelector(selectEmployeeListTableData);
    const tableMeta = useSelector(selectEmployeeMeta);
    const loadingStatus = useSelector(selectEmployeeListLoading);
    const totalEmployee = useSelector(selectEmployeeListTotal);

    const columns = [
        {
            title: t('table.employee_code'),
            key: 'employee_code',
            width: 120,
            render: (employee: Employee) => {
                return <div>
                    {employee.account?.info?.employee_code ?? ''}
                </div>
            }
        },
        {
            title: t('table.employee'),
            key: 'email',
            width: 300,
            render: (employee: Employee) =>
                <div>
                    {employee.account?.email ?? ''}
                </div>
        },
        {
            title: t('table.workspace'),
            key: 'workspace',
            width: 150,
            render: (employee: Employee) =>
                <div>
                    {employee.workspace?.name ?? ''}
                </div>
        },
        {
            title: t('table.role'),
            key: 'role',
            width: 200,
            render: (employee: Employee) =>
                <div>
                    {employee.role?.name}
                </div>
        },
        {
            title: t('table.created_at'),
            dataIndex: 'created_at',
            key: 'created_at',
            width: 150,
            render: (createdAt: string) =>
                <div>
                    {format(new Date(createdAt), DISPLAY_DATE_FORMAT)}
                </div>
        },
        {
            title: t('table.created_by_id'),
            key: 'created_by_id',
            width: 300,
            render: (employee: Employee) =>
                <div>
                    {employee.created_admin?.email}
                </div>
        },
        {
            title: t('table.status'),
            key: 'status',
            width: 100,
            render: (employee: Employee) =>
                employee.role?.is_admin
                    ?
                <div></div>
                    :
                <EmployeeStatus
                    status={employee.status}
                    id={employee.id}
                />
        },
        {
            title: t('table.action'),
            key: 'actions',
            width: 80,
            render: (employee: Employee) =>
                employee.role?.is_admin
                    ?
                <div></div>
                    :
                <div className={'text-center'}>
                    <EditEmployee employeeId={employee.id}/>
                </div>
        },
    ];
    const query = useQuery();
    const history = useHistory();

    const onChange = useCallback((page, pageSize) => {
        history.push({
            pathname: history.location.pathname,
            search: new URLSearchParams({
                ...query,
                page,
                limit: pageSize
            }).toString()
        })
    }, [history, query]);

    return (
        <div className={'mx-20 my-20'}>
            <p className={'font-medium'}>{t('filter.title')}</p>
            <SearchAndFilter/>
            <div className={'flex justify-between'}>
                <p>{totalEmployee + ' ' + t('name') }</p>
                <div >
                    <AddEmployee/>
                </div>
            </div>
            <Table
                {...defaultConfigTable}
                dataSource={tableData}
                columns={columns}
                className="border-solid border-greyEE border-1"
                pagination={{
                    position: ['bottomCenter'],
                    current: tableMeta?.current_page,
                    pageSize: tableMeta?.limit,
                    total: tableMeta?.total,
                    onChange,
                    showSizeChanger: false,
                }}
                rowKey={'id'}
                loading={loadingStatus}
            />
            <EmployeeModalForm />
        </div>
    );
}

export default EmployeeList;
