import {atom, useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {RouteWithCommand} from "react-hook-guard";
import {useEffect} from "react";

const titleAtom = atom({
    key: "app-title",
    default: ""
})

export const useAppTitleValue = () => useRecoilValue(titleAtom);
export const useSetAppTitle = () => {
    const setTitle = useSetRecoilState(titleAtom);
    return (title: string) => {
        setTitle(title);
        document.title = title;
    }
};
export const useAppTitle = () => useRecoilState(titleAtom);

export const useRouteTitleGuard = (route: RouteWithCommand) => {
    const setAppTitle = useSetAppTitle();
    useEffect(() => {
        if (route?.data?.title) {
            setAppTitle(route.data.title);
        }
    },[route, setAppTitle]);

    return true;
}
