import {RouterEnum} from "../../config/router.enum";
import axios from "../../common/axios";
import {Rest} from "../../common/models/rest";
import {BASE_URL} from "../../config/properties";


export class AccountListService {
    getAccountList() {
        return axios.get<Rest<any>>(`${BASE_URL}/${RouterEnum.account}/get-accounts`);
    }
}

const accountListService = new AccountListService();

export default accountListService;
