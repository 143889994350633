import {RouterEnum} from "../../../config/router.enum";
import axios from "../../../common/axios";
import {Rest} from "../../../common/models/rest";
import {BASE_URL} from "../../../config/properties";
import {Role} from "../../../models/role";


export class RoleListService {
    getRoleList(workspaceIds?: number[]) {
        return axios.get<Rest<Role[]>>(
            `${BASE_URL}/${RouterEnum.employee}/roles`,
            { params: {workspace_ids: workspaceIds?.join(',')} }
        );
    }
}

const roleListService = new RoleListService();

export default roleListService;
